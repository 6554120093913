<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} FAQ</h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <vs-input
          label="Title"
          v-model="title"
          class="w-full mb-5"
          name="title"
          validate="required"/>
        <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>

        <label class="block mb-2">Description</label>
        <vs-textarea
          v-model="description"
          class="w-full"
          name="description"
          validate="required"/>
        <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        dataId: null,
        title: "",
        description: "",
        settings: { // perfect scrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        customError: "",
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.dataId = this.data._id
          this.title = this.data.title
          this.description = this.data.description
          this.initValues()
        }
      },
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
          && this.title
          && this.description
      }
    },
    methods: {
      ...mapActions("setting", [
        "updateFaq",
        "addFaq"
      ]),
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      initValues() {
        if (this.data._id) return
        this.dataId = null
        this.title = ""
        this.description = ""
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let data = new FormData();
            data.append('id', this.dataId);
            data.append('title', this.title);
            data.append('description', this.description);
            this.customError = "";
            if (this.dataId !== null) {
              this.$vs.loading()
              this.updateFaq(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.showMessage("Success", "FAQ updated successfully.", "success");
                    this.$emit('refreshData', true);
                  }
                  this.$vs.loading.close()
                })
                .catch(err => {
                  this.$vs.loading.close()
                  //console.error(err)
                })
            } else {
              this.$vs.loading()
              this.addFaq(data)
                .then((res) => {
                  if (res.status === 200) {
                    this.$vs.loading.close()
                    this.$emit('refreshData', true)
                    this.showMessage("Success", "FAQ added successfully.", "success");
                  }
                })
                .catch(err => {
                  this.$vs.loading.close()
                  if(err.response.status === '422') {
                    this.showMessage("Failed", err.response.data.message, "danger");
                  }
                  this.showMessage("Failed","Failed to add FAQ", "danger");
                  // console.error(err)
                })
            }
            this.$emit('closeSidebar')
           // this.initValues()
          }
        })
      },
    },
    created(){
    },
    components: {
      vSelect,
      VuePerfectScrollbar,
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
